<template>
  <el-dialog :append-to-body="true" :close-on-click-modal="false" :visible.sync="dialog">
    <div class="head-container">
      <el-upload class="filter-item" ref="upload" :action="uploadUrl" :headers="uploadHeaders" :before-upload="handleBeforeUpload" :on-success="handleUploadSuccess" :on-error="handleUploadError" :show-file-list="false" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
        <el-button type="primary" class="filter-item" icon="el-icon-upload2" :loading="uploading">{{btnName}}</el-button>
      </el-upload>
      <!-- <el-button type="primary" @click="downloadTemplate" class="filter-item" icon="el-icon-download" :loading="downloadLoading">模板下载</el-button> -->
    </div>


        <div class="v" v-if="uploaded && uploaded.done && !uploaded.err">
          <span>导入结果：共{{uploaded.total}}条,成功{{uploaded.success}}条,失败{{uploaded.fail}}条</span>&nbsp;
          <div class="h c">
            <!-- <span>错误详情</span> &nbsp; -->
            <el-button @click="downloadErr" :loading="downloadLoading" type="danger" size="mini">错误导出</el-button>
          </div>
          &nbsp;
          <el-table :data="uploaded.detail" border style="width: 100%;">
            <el-table-column prop="erpCode" width="120" label="经销商ERP编码" />
            <el-table-column prop="name" width="180" label="经销商名称" />            
            <el-table-column prop="goodsErpCode" width="150" show-overflow-tooltip label="商品ERP编码" />
            <el-table-column prop="goodsName" min-width="100" show-overflow-tooltip label="商品名称" />
            <el-table-column prop="specName" min-width="100" show-overflow-tooltip label="规格" />
            <el-table-column prop="stock" width="100" label="期初" />
            <el-table-column prop="error" label="错误信息" width="150" show-overflow-tooltip />
          </el-table> 
          <!-- 库房编码	商品ERP编码	商品名称	库存数量 -->
        </div>
        <div v-if="uploaded && uploaded.done && uploaded.err">
          <span>{{uploaded.err}}</span>
        </div>

  </el-dialog>
</template>
<script>
import config from "@/config";
import { getToken } from "@/utils/auth";
import { download } from "@/api/data";
import { downloadFile } from "@/utils/index";
export default {
  data() {
    return {      
      uploading: false,
      entId: null,
      entName:null,
      uploadHeaders: {},
      downloadLoading: false,
      dialog: false,
      uploaded:{done:false},
    };
  },
  computed: {
    btnName() {
      return this.uploading ? "导入中" : "选择文件";
    },
    uploadUrl() {
      return config.hosts.request + "api/stockReportBop/import/" + this.entId;
    },
  },
  methods: {
    handleBeforeUpload() {
      this.uploading = true;
    },
    handleUploadSuccess(res) {      
      this.uploaded = res;
      this.uploaded.done = true;
      this.uploading = false;          
    },
    handleUploadError(err) {
      //this.errInfo = err;
      this.$message.err(err);
      this.uploading = false;
    },
    downloadErr(){
      if(!this.uploaded.detail || this.uploaded.detail.length==0) return;      
      this.downloadLoading = true;
      import("@/utils/export2Excel").then(excel => {
        const tHeader = [
          "经销商ERP编码",
          "经销商名称",
          "库房编码",
          "商品ERP编码",
          "商品名称",
          "规格",
          "期初",
          "错误信息"
        ];
        const filterVal = [
          "erpCode",
          "name",
          "stockCode",
          "goodsErpCode",
          "goodsName",
          "specName",
          "stock",
          "error"
        ];
        const data = this.formatJson(filterVal, this.uploaded.detail);
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: this.entName+"_期初未导入信息"
        });
        this.downloadLoading = false;
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v =>
        filterVal.map(j => {          
            return v[j];          
        })
      );
    },
    resetForm(entId,entName) {
      this.uploadHeaders.Authorization = "Bearer " + getToken();
      this.entId = entId;
      this.entName = entName;
      this.uploaded={done:false};
      this.dialog = true;
    },
  },
};
</script>